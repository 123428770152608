/*
 * Seo - rankingcoach
 * Diese Funktion ruft einen externen Dienst auf.
 * TODO: andere Funktionsname, Parameter aus HTML löschen, eigene Datei, Tests
 *
 * @method domain_url
 */

// DE
window.domain_url = function () {
    var returnUrl   = '',
        domainName  = '';

    // URL von rankingcoach
    returnUrl = document.getElementsByName('return_url')[0].value;

    // vom Nutzer eingegebener Domain Name
    domainName = document.getElementById('valid_domain').value;

    // öffnet neues Window
    window.open('https://www.rankingcoach.com/de-de/analysis-strato/' + domainName + '?return_url=' + returnUrl, '_blank');
};

// SE
window.domain_url_se = function () {
    var returnUrl   = '',
        domainName  = '';

    // URL von rankingcoach
    returnUrl = document.getElementsByName('return_url')[0].value;

    // vom Nutzer eingegebener Domain Name
    domainName = document.getElementById('valid_domain').value;

    // öffnet neues Window
    window.open('https://www.rankingcoach.com/sv-se/analysis-strato/' + domainName + '?return_url=' + returnUrl, '_blank');
};


// ES
window.domain_url_es = function () {
    var returnUrl   = '',
        domainName  = '';

    // URL von rankingcoach
    returnUrl = document.getElementsByName('return_url')[0].value;

    // vom Nutzer eingegebener Domain Name
    domainName = document.getElementById('valid_domain').value;

    // öffnet neues Window
    window.open('https://www.rankingcoach.com/es-es/analysis-strato/' + domainName + '?return_url=' + returnUrl, '_blank');
};


// NL
window.domain_url_nl = function () {
    var returnUrl   = '',
        domainName  = '';

    // URL von rankingcoach
    returnUrl = document.getElementsByName('return_url')[0].value;

    // vom Nutzer eingegebener Domain Name
    domainName = document.getElementById('valid_domain').value;

    // öffnet neues Window
    window.open('https://www.rankingcoach.com/nl-nl/analysis-strato/' + domainName + '?return_url=' + returnUrl, '_blank');
};
