//@ts-check

const tableFeature = document.querySelector('[data-js="tableFeature"]');
if (tableFeature) {
    let vlz1Elements = document.querySelectorAll('.table-feature[data-vlz="1"]');
    let vlz12Elements = document.querySelectorAll('.table-feature[data-vlz="12"]');

    init(tableFeatureOpenFirst);
    // get all tables with vlz 1
    addSyncedTableEvent(vlz1Elements, '12');
    // get all tables with vlz 12
    addSyncedTableEvent(vlz12Elements, '1');
    // handle tooltips
    handleTooltips();
}

function handleTooltips() {
    let tableFeatureTooltips = document.querySelectorAll('.table-feature .table__content .table__row:nth-child(odd) .table__subline .tooltip__infoicon');
    let z;
    for (z = 0; z < tableFeatureTooltips.length; z++) {
        tableFeatureTooltips[z].addEventListener('mouseover', tableFeatureTooltipsMouseOver);
        tableFeatureTooltips[z].addEventListener('mouseout', tableFeatureTooltipshandleMouseOut);
    }
}
// open first accordions on load
function tableFeatureOpenFirst() {


    let zwoelf = document.querySelectorAll('.table-feature[data-vlz="12"]');
    if (!zwoelf[0]) return;
    zwoelf[0].firstElementChild.classList.toggle('active');
    let content12First = zwoelf[0].firstElementChild.nextElementSibling;
    content12First.style.maxHeight = content12First.scrollHeight + 'px';
    content12First.classList.add('open');

    let eins = document.querySelectorAll('.table-feature[data-vlz="1"]');
    if (!eins[0]) return;
    eins[0].firstElementChild.classList.toggle('active');
    let content1First = eins[0].firstElementChild.nextElementSibling;
    content1First.style.maxHeight = content12First.scrollHeight + 'px';
    content1First.classList.add('open');
}

function init(tableFeatureOpenFirst) {
    if (document.readyState === 'complete') {
        tableFeatureOpenFirst();
    } else {
        window.addEventListener('load', tableFeatureOpenFirst);
    }
}

/** Adds click event to expand/collapse the accordion of the specified elements and the synced elements
 *  @param vlzElements List of Elements to receive Event
 *  @param syncTargetVlz data-vlz Value of elements to be synced with the current table (1 or 12) */
function addSyncedTableEvent(vlzElements, syncTargetVlz) {
    for (let vlzIndex = 0; vlzIndex < vlzElements.length; vlzIndex++) {
        vlzElements[vlzIndex].dataset.vlzindex = vlzIndex;
        let clickBar12 = vlzElements[vlzIndex].querySelector('.table__head');
        clickBar12.addEventListener('click', function() {
            let content12 = this.nextElementSibling;
            let vlzindex = this.parentElement.dataset.vlzindex;
            let vlz1TargetIndex = document.querySelector('.table-feature[data-vlz="' + syncTargetVlz + '"][data-vlzindex="' + vlzindex + '"]');
            this.classList.toggle('active');
            toggleOpen(content12);
            if (vlz1TargetIndex === null) return;
            if (!vlz1TargetIndex.firstElementChild.nextElementSibling) return;
            let content12_12 = vlz1TargetIndex.firstElementChild.nextElementSibling;
            if (!content12_12) return;
            vlz1TargetIndex.firstElementChild.classList.toggle('active');
            toggleOpen(content12_12, content12);
        });
    }
}

function tableFeatureTooltipsMouseOver(e) {
    e.target.closest('.table__row').style.zIndex = '5';
    e.target.closest('.table-feature').style.zIndex = '50';
}

function tableFeatureTooltipshandleMouseOut(e) {
    e.target.closest('.table__row').style.zIndex = '1';
    e.target.closest('.table-feature').style.zIndex = '10';
}
// zindex reset, weil sonst nach dem tooltip hover die table-feature z-index bleibt und die anderen table-features nicht mehr klickbar sind
document.addEventListener('click', function (e) {
    const tableFeature = e.target.closest('.table-feature');
    if (!tableFeature) return;
    tableFeature.style.zIndex = null;
});
/**
 * Adds/removes the “open”-class and maxHeight of specified element, depending on whether the target element has no/a maxHeight
 * @param targetElement Element to be toggled
 * @param refElement  Optional Element to be referenced for maxHeight calculation | default=currentElement
 */
function toggleOpen(targetElement, refElement = targetElement){
    if(targetElement.style.maxHeight){
        targetElement.style.maxHeight = null;
        targetElement.classList.remove('open');
    } else {
        targetElement.style.maxHeight = refElement.scrollHeight + 'px';
        targetElement.classList.add('open');
    }
}

/*
    SWE-283 => [SEO] Vergleichsseiten Tabelle

*/
let seoTableFeatures = document.querySelectorAll('.seotable-feature');
openSeoTableContent(seoTableFeatures);

document.addEventListener('DOMContentLoaded', function() {
    //trigger click on all first-seoFeatureChild elements
    let firstSeoFeatureChild = document.querySelectorAll('.first-seoFeatureChild > .table__head');
    firstSeoFeatureChild.forEach(element => {
        element.click();
    });
});

/**
 *
 * @param {NodeListOf<Elements>} seoTableFeatures
 */
function openSeoTableContent(seoTableFeatures){
    seoTableFeatures.forEach(element => {
        let tableHead = element.firstElementChild;
        tableHead.addEventListener('click', function(){
            let contentElement = element.querySelector('.table__content');
            element.firstElementChild.classList.toggle('active');
            toggleOpen(contentElement);
        });
    });
}
